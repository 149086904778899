@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Madimi+One&display=swap");

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #4185c4;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(70, 70, 70);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bf2626;
}

::-moz-selection {
  /* Code for Firefox */
  color: rgb(202, 36, 217);
  background: rgb(0, 0, 0);
}

::selection {
  color: rgb(255, 255, 255);
  background: rgb(68, 174, 30);
}
